.pill_container {
  margin-left: 16px;
  margin-right: 16px;
}

.pill {
  background-color: transparent !important;
}

.pill:hover {
  color: #747980 !important;
}

.pill_selected {
  padding: 8px 16px 8px 16px !important;
  background-color: rgb(236, 236, 236) !important;
}

.pill_selected:hover {
  background-color: rgb(217, 217, 217) !important;
}